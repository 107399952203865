<template>
	<div class="acao-experiencia-form-container">
		<div
			class="info-container"
		>
			<b-tooltip
				multilined
				size="is-large"
				type="is-white"
			>
				<b-icon
					icon="information-outline"
					custom-size="mdi-1rem"
				/>

				<template #content>
					<span>
						Você pode participar de <strong>diversas experiências
							simultaneamente</strong> e será notificado caso
						seja contemplado. As vagas são <strong>limitadas</strong>.
					</span>
				</template>
			</b-tooltip>

			<span>Qual seu interesse? Selecione:</span>
		</div>

		<b-tooltip
			class="interesse-container"
			multilined
			size="is-large"
		>
			<div
				class="interesse-item"
				:class="{
					'disabled': possuiBloqueio,
				}"
				@click="seleciona(InteresseValorEnum.MEDIO)"
			>
				<Icon
					:file="interesseMedioIcone"
					size="65:55"
				/>

				<span>Médio</span>
			</div>

			<div
				class="interesse-item"
				:class="{
					'disabled': interesseAltoDesativado || possuiBloqueio,
				}"
				@click="seleciona(InteresseValorEnum.ALTO)"
			>
				<Icon
					:file="interesseAltoIcone"
					size="65:55"
				/>

				<span>Alto</span>
			</div>

			<div
				class="interesse-item"
				:class="{
					'disabled': interesseMuitoAltoDesativado || possuiBloqueio,
				}"
				@click="seleciona(InteresseValorEnum.MUITO_ALTO)"
			>
				<Icon
					:file="interesseMuitoAltoIcone"
					size="65:55"
				/>

				<span>Muito Alto</span>
			</div>

			<template #content>
				<span
					v-if="possuiBloqueio"
				>
					{{ motivoBloqueio }}
				</span>
				<span
					v-else
				>
					Você pode escolher os níveis de
					interesse "Alto" e "Muito alto" apenas
					uma vez para as novas experiências.
				</span>
			</template>
		</b-tooltip>

		<b-tooltip
			v-if="possuiBloqueio"
			multilined
			size="is-large"
		>
			<div
				class="botao-container"
			>
				<b-button
					type="is-primary"
					rounded
					expanded
					:disabled="true"
					@click="concorrer"
				>
					Concorrer
				</b-button>
			</div>
			<template #content>
				{{ motivoBloqueio }}
			</template>
		</b-tooltip>
		<div 
			v-else
			class="botao-container"
		>
			<b-button
				type="is-primary"
				rounded
				expanded
				:disabled="!permiteConcorrer || possuiBloqueio"
				@click="concorrer"
			>
				Concorrer
			</b-button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@components/Icon';
import {
	InteresseValorEnum,
	InteresseIconeEnum,
	InteresseIconeSelecionadoEnum,
} from '@enum/experiencias/interesse';

const INTERESSE_ICONE_DESATIVADO = 'experiencias/interesse_0';

export default {
	name: 'AcaoExperienciaForm',

	components: {
		Icon,
	},

	props: {
		id: {
			required: true,
			type: Number,
		},
		horarioDisponivel: {
			required: true,
			type: Boolean,
		},
	},

	data() {
		return {
			InteresseValorEnum,
			selecionado: '',
		};
	},

	computed: {
		...mapGetters(
			'experiencias',
			{
				altoSelecionado: 'getInteresseAlto',
				muitoAltoSelecionado: 'getInteresseMuitoAlto',
				temBloqueio: 'temBloqueio',
			}
		),

		possuiBloqueio() {
			return this.temBloqueio || !this.horarioDisponivel;
		},

		motivoBloqueio() {
			return this.temBloqueio !== false ? this.temBloqueio : 'Você já está matriculado em uma experiência nesta mesma data e horário!';
		},

		interesseMedioIcone() {
			if(!this.possuiBloqueio) {
				return this.selecionado === InteresseValorEnum.MEDIO
				? InteresseIconeSelecionadoEnum[InteresseValorEnum.MEDIO]
				: InteresseIconeEnum[InteresseValorEnum.MEDIO];
			} else {
				return INTERESSE_ICONE_DESATIVADO;
			}
			
		},

		interesseAltoDesativado() {
			return this.altoSelecionado.id != this.id
				&& this.altoSelecionado.confirmado;
		},

		interesseAltoIcone() {
			if (!this.altoSelecionado.id) {
				return InteresseIconeEnum[InteresseValorEnum.ALTO];
			}

			if (this.interesseAltoDesativado || this.possuiBloqueio) {
				return INTERESSE_ICONE_DESATIVADO;
			}

			return this.selecionado === InteresseValorEnum.ALTO
				&& this.altoSelecionado.id == this.id
				? InteresseIconeSelecionadoEnum[InteresseValorEnum.ALTO]
				: InteresseIconeEnum[InteresseValorEnum.ALTO];
		},

		interesseMuitoAltoDesativado() {
			return this.muitoAltoSelecionado.id != this.id
				&& this.muitoAltoSelecionado.confirmado;
		},

		interesseMuitoAltoIcone() {
			if (!this.muitoAltoSelecionado.id) {
				return InteresseIconeEnum[InteresseValorEnum.MUITO_ALTO];
			}

			if (this.interesseMuitoAltoDesativado || this.possuiBloqueio) {
				return INTERESSE_ICONE_DESATIVADO;
			}

			return this.selecionado === InteresseValorEnum.MUITO_ALTO
				&& this.muitoAltoSelecionado.id == this.id
				? InteresseIconeSelecionadoEnum[InteresseValorEnum.MUITO_ALTO]
				: InteresseIconeEnum[InteresseValorEnum.MUITO_ALTO];
		},

		permiteConcorrer() {
			return this.selecionado === InteresseValorEnum.MEDIO
				|| (
					this.selecionado === InteresseValorEnum.ALTO
					&& this.altoSelecionado.id == this.id
				) || (
					this.selecionado === InteresseValorEnum.MUITO_ALTO
					&& this.muitoAltoSelecionado.id == this.id
				);
		}
	},

	methods: {
		seleciona(valor) {
			this.selecionado = valor;

			if (this.selecionado === InteresseValorEnum.ALTO) {
				this.$store.dispatch('experiencias/setInteresseAlto', this.id);
			} else if (this.selecionado === InteresseValorEnum.MUITO_ALTO) {
				this.$store.dispatch('experiencias/setInteresseMuitoAlto', this.id);
			}
		},

		async concorrer() {
			if (!this.permiteConcorrer) {
				this.selecionado = null;

				this.$store.dispatch(
					'ui/toast/openError',
					'Não foi possível executar essa ação, tente novamente'
				);

				return;
			}

			this.$emit('concorrer', this.selecionado);
		},
	},
}
</script>

<style lang="scss" scoped>
.acao-experiencia-form-container {
	display: flex;
	gap: 1rem;
	flex-direction: column;
	align-items: center;
}

.botao-container {
	width: 100%;
	max-width: 280px;
}

.interesse-container {
	::v-deep .tooltip-content {
		font-size: 1rem;
		line-height: 1.5rem;
		box-shadow: unset;
	}

	::v-deep .tooltip-trigger {
		display: flex;
		gap: 1rem;
	}
}

.interesse-item {
	display: flex;
	gap: 0.75rem;
	flex-direction: column;
	align-items: center;
	cursor: pointer;

	::v-deep .custom-icon {
		line-height: 0;
	}

	&.disabled {
		cursor: not-allowed;
		color: #c5c5c5;
	}
}

::v-deep {
	.b-tooltip.is-white {
		.tooltip-content {
			background-color: white !important;
			color: #525252 !important;
			padding: 1rem;

			&::before {
				filter: drop-shadow(0px 3px 1px rgba(0, 1, 0, 0.2));
			}
		}
	}
}
</style>
