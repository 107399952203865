<template>
	<AcaoExperienciaStatus
		v-if="exibeStatus"
		:id="id"
	/>

	<AcaoExperienciaForm
		v-else
		:id="id"
		:horario-disponivel="horarioDisponivel"
		@concorrer="concorrer"
	/>
</template>

<script>
import AcaoExperienciaForm from '@components/experiencias/AcaoExperienciaForm';
import AcaoExperienciaStatus from '@components/experiencias/AcaoExperienciaStatus';

export default {
	name: 'AcaoExperiencia',

	components: {
		AcaoExperienciaForm,
		AcaoExperienciaStatus,
	},

	props: {
		id: {
			required: true,
			type: Number,
		},
		horarioDisponivel: {
			required: true,
			type: Boolean,
		},
	},

	data() {
		return {
			confirmado: false,
		};
	},

	computed: {
		exibeStatus() {
			return this.confirmado
				|| this.$store.getters['experiencias/getMinhasExperiencias']
					.some(exp => exp.id === this.id)
			;
		}
	},

	methods: {
		async concorrer(prioridade) {
			try {
				await this.$store.dispatch(
					'experiencias/concorrer',
					{
						id: this.id,
						prioridade,
					}
				);

				this.$emit('confirmado', prioridade);

				this.confirmado = true;
			} catch (error) {
				this.$store.dispatch('ui/toast/openError', error.message);
			}
		},
	},
}
</script>

