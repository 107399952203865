var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"acao-experiencia-form-container"},[_c('div',{staticClass:"info-container"},[_c('b-tooltip',{attrs:{"multilined":"","size":"is-large","type":"is-white"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v(" Você pode participar de "),_c('strong',[_vm._v("diversas experiências simultaneamente")]),_vm._v(" e será notificado caso seja contemplado. As vagas são "),_c('strong',[_vm._v("limitadas")]),_vm._v(". ")])]},proxy:true}])},[_c('b-icon',{attrs:{"icon":"information-outline","custom-size":"mdi-1rem"}})],1),_c('span',[_vm._v("Qual seu interesse? Selecione:")])],1),_c('b-tooltip',{staticClass:"interesse-container",attrs:{"multilined":"","size":"is-large"},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.possuiBloqueio)?_c('span',[_vm._v(" "+_vm._s(_vm.motivoBloqueio)+" ")]):_c('span',[_vm._v(" Você pode escolher os níveis de interesse \"Alto\" e \"Muito alto\" apenas uma vez para as novas experiências. ")])]},proxy:true}])},[_c('div',{staticClass:"interesse-item",class:{
				'disabled': _vm.possuiBloqueio,
			},on:{"click":function($event){return _vm.seleciona(_vm.InteresseValorEnum.MEDIO)}}},[_c('Icon',{attrs:{"file":_vm.interesseMedioIcone,"size":"65:55"}}),_c('span',[_vm._v("Médio")])],1),_c('div',{staticClass:"interesse-item",class:{
				'disabled': _vm.interesseAltoDesativado || _vm.possuiBloqueio,
			},on:{"click":function($event){return _vm.seleciona(_vm.InteresseValorEnum.ALTO)}}},[_c('Icon',{attrs:{"file":_vm.interesseAltoIcone,"size":"65:55"}}),_c('span',[_vm._v("Alto")])],1),_c('div',{staticClass:"interesse-item",class:{
				'disabled': _vm.interesseMuitoAltoDesativado || _vm.possuiBloqueio,
			},on:{"click":function($event){return _vm.seleciona(_vm.InteresseValorEnum.MUITO_ALTO)}}},[_c('Icon',{attrs:{"file":_vm.interesseMuitoAltoIcone,"size":"65:55"}}),_c('span',[_vm._v("Muito Alto")])],1)]),(_vm.possuiBloqueio)?_c('b-tooltip',{attrs:{"multilined":"","size":"is-large"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.motivoBloqueio)+" ")]},proxy:true}],null,false,1034848409)},[_c('div',{staticClass:"botao-container"},[_c('b-button',{attrs:{"type":"is-primary","rounded":"","expanded":"","disabled":true},on:{"click":_vm.concorrer}},[_vm._v(" Concorrer ")])],1)]):_c('div',{staticClass:"botao-container"},[_c('b-button',{attrs:{"type":"is-primary","rounded":"","expanded":"","disabled":!_vm.permiteConcorrer || _vm.possuiBloqueio},on:{"click":_vm.concorrer}},[_vm._v(" Concorrer ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }